import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "extended-essay",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#extended-essay",
        "aria-label": "extended essay permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Extended Essay`}</h3>
    <p>{`The Extended Essay (EE), is a 4,000-word piece of independent research, completed over the course of the 2-year IB Diploma Programme, which allows students to inquire into a topic of special interest. The purpose of the EE is primarily to teach students the research, time management and writing skills they will need to thrive in University, where they will be asked to produce academic papers on a regular basis. In speaking with PSI alumni, many have spoken about the value of the EE and the role that it played in preparing them for University, in some cases indicating that it was the single most useful thing they did to prepare for life after high school.`}</p>
    <p>{`This year, students started the Extended Essay process in January of their Grade 11 year, and had more than 12 months to complete and submit the final essay. In order to help students succeed, the essay was broken down into a number of smaller components, each with its own internal deadline, and carefully scaffolded over the course of the 12 months. The students in the class of 2020 chose topics in a number of areas, ranging from geography to mathematics to visual arts. They looked at questions as varied as the use of language in Men’s Health magazine; the economic impact of plastics recycling systems in Europe; the increase in human trafficking due to the conflict in the Donbass; the legitimacy of tribal governance in the United States; and the use of social media in fashion marketing. Out of our graduating class of 25 students, 16 chose to pursue the full IB Diploma and completed an Extended Essay. Of those 16 students, over half are predicted to receive grades of A or B on the essay.`}</p>
    <h3 {...{
      "id": "eca-programme-expansion-story",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#eca-programme-expansion-story",
        "aria-label": "eca programme expansion story permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ECA Programme Expansion Story`}</h3>
    <p>{`It's no secret that `}<strong parentName="p">{`After-School Activities`}</strong>{` do wonders for children's development, improve their social skills, and / or get them active in sports.`}</p>
    <p>{`The PSI Extracurricular Activities (ECA) Programme is a complex programme that unites various kinds of after school classes. It is dedicated to providing PSI students with exciting new opportunities and high quality experiences.`}</p>
    <p>{`It also includes Mother Tongue and the Super Saturday Sports programmes. This year, the `}<strong parentName="p">{`Sports Activity`}</strong></p>
    <p><strong parentName="p">{`Programme (SAP)`}</strong>{` was set up with swimming and a climbing wall. Both programmes will be extended in 2020 / 2021 to give students the opportunity to have a progressive programme of development.`}</p>
    <p><strong parentName="p">{`CHQ System`}</strong>{`  - After considerable research, we moved to the preference-based approach for  signups for Season 2 After School / Saturday Activities to address the pressure on parents that the former ‘first-come first-served’ system caused. Under the preference-based system, all parents indicate preferences for their child/ren and the computer algorithm places as many children as it can into their first preference activity. The Waiting List allows us to add students if spaces become available. All information on students involved in After School / Saturday Activities is placed in one system: ECA, SAP, Mother Tongue classes, Academic / Year-Long Projects, Panthers Athletics, and Panthers Academics.`}</p>
    <p><strong parentName="p">{`The Virtual ECA Programme`}</strong>{` - In April 2020, PSI launched a new Virtual ECA Programme, scheduled from Monday to Friday, and facilitated on the Zoom platform. With the Virtual ECA Programme, we aimed to promote a healthy and balanced lifestyle and added social engagement to our PSI children’s diaries. The programme consisted of dynamic activities such as Zumba, Martial Arts, Aikido Fitness, Workout & Dance, Guitar, IT clubs (Movie Making, Scratch Programming) and Mother Tongue lessons.`}</p>
    <p><strong parentName="p">{`“Good, better, best”.`}</strong></p>
    <h3 {...{
      "id": "green-committee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#green-committee",
        "aria-label": "green committee permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Green Committee`}</h3>
    <p>{`The Green Committee is committed to supporting PSI becoming a UNESCO `}<a parentName="p" {...{
        "href": "https://www.ecoschools.global/"
      }}>{`Eco School`}</a>{`. “Eco-Schools is the largest global sustainable schools programme – it starts in the classroom and expands to the community by engaging the next generation in action-based learning.” Eco Schools have 12 different themes that students can study to guide their actions. PSI’s Green Committee has chosen to focus on three areas:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Biodiversity: Examines the flora and fauna present in the school environment and suggests ways to increase the levels of biodiversity around the school and raises the pupils’ awareness of biodiversity and nature.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Energy: Suggests ways in which all members of the school can work together to increase awareness of energy issues and to improve energy efficiency within the school.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Waste: Examines the impact of waste on the environment and explores actions to minimise the amount of waste that we produce and dispose of on a daily basis.`}</p>
      </li>
    </ul>
    <p>{`The Green Committee is composed of three smaller teams: The Primary Green Team, the Secondary Green Team, and the Adult Green Team. The Primary Primary Green Team is for students in grades 3, 4,and 5 and the Secondary Green Team is for students from grades 6-12. These students work together once a week through the ECA program. The Adult Green Team consists of teachers, administration, and parents, meeting once a month. The Full Green Committee, including Primary, Secondary and Adult Green Teams,  come together once every three months  to discuss projects based on the three chosen themes.`}</p>
    <p>{`During the 2019/2020 school year the Primary and Secondary teams worked to teach their peers about the importance of the environment and how they can support it by composting and proper recycling. The Secondary Team also focused on putting plants into each classroom, developing a gardening program at the school and supporting “Meatless Mondays” in the cafeteria. The Adult Green Team looked into making curricular connections, applying for the UN Climate Change Conference, sharing information with our school community about how to recycle properly, and opening up a recycling station at school for families to drop off their recycling. The Adult Green Team is also working to make school functions more environmentally friendly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      